import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import Sidebar from "../components/Sidebar";
import ContainerForm from "../components/ContainerForm";
import "./AdminDashboard.css";

interface Container {
  id: string;
  location: string;
  coordinates: { lat: number; lng: number };
  timeline: { date: string; location: string }[];
  ETD: string;
  ETA: string;
  portOfLoading: string;
  portOfDischarge: string;
  shipImage: string;
  shipName: string;
}

const AdminDashboard: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [containers, setContainers] = useState<Container[]>([]);
  const [editingContainer, setEditingContainer] = useState<Container | null>(
    null
  );

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const fetchContainers = async () => {
    try {
      const response = await fetch(
        "https://api.auresmotors.workers.dev/containers"
      );
      const data = await response.json();
      setContainers(data);
    } catch (error) {
      console.error("Failed to fetch containers:", error);
    }
  };

  const handleAddContainer = async (data: Container) => {
    try {
      const response = await fetch(
        "https://api.auresmotors.workers.dev/containers",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        fetchContainers();
        setModalOpen(false);
      } else {
        console.error("Failed to add container:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to add container:", error);
    }
  };

  const handleDeleteContainer = async (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://api.auresmotors.workers.dev/containers/${id}`,
            {
              method: "DELETE",
            }
          );
          if (response.ok) {
            fetchContainers();
            Swal.fire("Deleted!", "The container has been deleted.", "success");
          } else {
            console.error("Failed to delete container:", response.statusText);
          }
        } catch (error) {
          console.error("Failed to delete container:", error);
          Swal.fire("Error!", "Something went wrong while deleting.", "error");
        }
      }
    });
  };

  const handleEditContainer = (container: Container) => {
    setEditingContainer(container); // Set container data to be edited
    setModalOpen(true);
  };

  const handleAddNewContainer = () => {
    setEditingContainer(null); // Clear previous data
    setModalOpen(true); // Open modal
  };

  useEffect(() => {
    fetchContainers();
  }, []);

  return (
    <div className="admin-dashboard">
      <button className="toggle-sidebar" onClick={toggleSidebar}>
        ☰
      </button>

      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

      <div className="content-area">
        <h2 style={{ margin: "10px 60px" }}>Manage Containers</h2>
        <button className="btn btn-add" onClick={handleAddNewContainer}>
          Add Container
        </button>

        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <ContainerForm
                onSubmit={(data) =>
                  editingContainer
                    ? handleAddContainer({ ...editingContainer, ...data })
                    : handleAddContainer(data)
                }
                existingData={editingContainer || undefined}
              />
              <button className="close-btn" onClick={() => setModalOpen(false)}>
                ✖
              </button>
            </div>
          </div>
        )}

        {/* Container List */}
        <div className="container-list">
          {containers.map((container) => (
            <div key={container.id} className="container-item">
              <h3>{container.shipName}</h3>
              <p>
                <strong>ID:</strong>{" "}
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  title="Click to copy ID"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(container.id);
                      alert(`Copied ID: ${container.id}`);
                    } catch (err) {
                      alert("Failed to copy ID. Please try again.");
                    }
                  }}
                >
                  {container.id}
                </span>
              </p>
              <p>
                <strong>Location:</strong> {container.location}
              </p>
              <p>
                <strong>ETD:</strong> {container.ETD}
              </p>
              <p>
                <strong>ETA:</strong> {container.ETA}
              </p>
              <div className="actions">
                <button
                  className="btn btn-edit"
                  onClick={() => handleEditContainer(container)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-delete"
                  onClick={() => handleDeleteContainer(container.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
