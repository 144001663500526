import React from "react";
import "./Sidebar.css";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  return (
    <div className={`sidebar-overlay ${isOpen ? "open" : ""}`}>
      <div className="sidebar">
        <button className="close-btn" onClick={onClose}>
          ✖
        </button>
        <h2>Admin Dashboard</h2>
        <a href="/">Manage Containers</a>
      </div>
    </div>
  );
};

export default Sidebar;
